import React, { useContext, useState, useEffect } from 'react'
//import { useForm } from "react-hook-form";
import start1 from "../../assets/vector/startWaifu1.svg"
import start2 from "../../assets/vector/startWaifu2.svg"
import start3 from "../../assets/vector/startWaifu3.svg"
import start4 from "../../assets/vector/startWaifu4.svg"
import start5 from "../../assets/vector/startWaifu5.svg"
import PaginationComponent from '../elements/PaginationComponent';
import UserContext from '../../context/userContext/UserContext';
import Loader from '../../components/elements/ModalX/loader';
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";
import MarketFilters from '../MarketFilters/MarketFilters';
import Loading from '../Loading/Loading';

import cottonHouse from "../../assets/img/marketHouses/cottonHouse.png";
import leatherHouse from "../../assets/img/marketHouses/leatherHouse.png";
import lineHouse from "../../assets/img/marketHouses/lineHouse.png";
import pineappleHouse from "../../assets/img/marketHouses/pineappleHouse.png";
import silkHouse from "../../assets/img/marketHouses/silkHouse.png";
import woolHouse from "../../assets/img/marketHouses/woolHouse.png";

import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";

import barnBackground from "../../assets/img/backgroundSections/marketBackground.png";
import plainBackground from "../../assets/img/backgroundSections/harvestBackground.png";
import { element } from 'prop-types'


export default function MarketWaifu({ setMarketBackground, setMarketBgEffect }) {
    const [arrayIcon] = useState(['', cottonIcon, woolIcon, leatherIcon, leaveIcon, flaxIcon, silkIcon])
    const [listItemsFilter, setListItemsFilter] = useState(null)
    const [listItemsFiltered, setListItemsFiltered] = useState(null)
    const [listItems, setListItems] = useState(null)
    const [selectStar, setSelectStar] = useState(0)
    const [selectHouse, setSelectHouse] = useState(0)
    const [loading, setLoading] = useState(false)
    const [allowance, setAllowance] = useState(false)
    const [filtered, filtered_action] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)


    //const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const { topData, contract, account, chainId } = useContext(UserContext)

    useEffect(() => {
        let petitionsWaifu = async () => {
            if (contract && account && !loading) {
                setSelectHouse(0)

                let offset = 0
                let allWaifuList = []
                let flagItems = false
                while (!flagItems) {

                    let getAllItems = []
                    let result = await contract.contractWaifu.methods.list(offset).call()
                    result.forEach((element, index) => {
                        if (element !== "0" && index !== 12) getAllItems.push(contract.contractWaifu.methods.getFarmer(element).call())
                        if (element === "0") flagItems = true
                    })
                    offset = result[12]

                    Promise.all(getAllItems).then(result => {
                        const listAllWaifus = result.reverse().map(element => ({ adn: '0'.repeat(61 - element.dna.length) + element.dna, ...element, waifuPower: Math.max(...element.waifuPowers) }))
                        listAllWaifus.map(element => allWaifuList.push(element))
                        
                        setListItems(allWaifuList)
                        setListItemsFilter(allWaifuList)
                        setListItemsFiltered(allWaifuList)
                    })
                }
            }
        }

        petitionsWaifu()


    }, [contract, account, loading, chainId])

    useEffect(() => {
        if (contract) contract.contractPeach.methods.allowance(account, process.env.GATSBY_DWGAME).call().then(result => parseInt(result) > 0 && setAllowance(true)).catch(err => console.error(err))
    }, [contract])

    useEffect(() => {
        if (selectHouse) {
            setMarketBackground(barnBackground);
            setMarketBgEffect("blur-md");
        }
        else {
            setMarketBackground(plainBackground);
            setMarketBgEffect('');
        }
    }, [selectHouse])

    useEffect(() => { selectHouse !== 0 && selectHouseCards(selectHouse - 1) }, [listItemsFilter]);


    const handleFilterStart = (value) => {
        filtered_action(true);
        if (value === "all") return setListItemsFilter(listItems)
        else if (value === "1") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 49))
        else if (value === "2") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 99 && parseInt(element.waifuPower) > 49))
        else if (value === "3") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 149 && parseInt(element.waifuPower) > 99))
        else if (value === "4") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 199 && parseInt(element.waifuPower) > 149))
        else if (value === "5") setListItemsFilter(listItems.filter(element => parseInt(element.waifuPower) <= 250 && parseInt(element.waifuPower) > 199))

    }

    const HaventWaifus = () => (
        <div className="grid grid-rows-mint justify-center text-center  ">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 m-auto "></img>
            <span className="font-bold text-xl text-[#40bfb3] flex justify-center items-center">There aren't <img src={arrayIcon[selectHouse]} alt="material market" className="w-8 p-1" /> waifus for sale</span>
        </div>
    );
    const NotFilter = () => (
        <div className="grid grid-rows-mint justify-center text-center  ">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-52 m-auto "></img>
            <span className="font-bold text-xl text-black flex justify-center items-center">There aren't <img src={arrayIcon[selectHouse]} alt="material market" className="w-8 p-1" /> waifus for sale</span>
        </div>
    );

    const buyWaifu = (element) => {
        if (contract) {
            setLoading(true)
            contract.contractDWGameFarmer.methods.buy(element.id).send({ from: account, value: parseInt(element.sellPrice) + Math.pow(10, 13) }).then(result => setLoading(false)).catch(err => setLoading(false));
        }
    }


    const selectHouseCards = (house) => {
        if (listItems) {


            setSelectStar(0);
            filtered_action(false);
            if (house === 0) setListItemsFiltered(listItems.filter(element => element.waifuPowers.indexOf(Math.max(...element.waifuPowers).toString()) === house))
            setListItemsFiltered(listItemsFilter.filter(element => (element.waifuPowers.indexOf(Math.max(...element.waifuPowers).toString()) === house || (element.waifuPowers[0] === element.waifuPowers[1] && element.waifuPowers[0] === element.waifuPowers[2] && element.waifuPowers[0] === element.waifuPowers[3]))))
        }
    }


    return (
        <div className="p-5 px-10 relative ">
            <Loading modal={loading} setModal={setLoading} />

            {!selectHouse ?
                <div className="grid md:grid-cols-3 grid-rows-6 md:grid-rows-2 gap-y-24 mt-52">
                    <img src={cottonHouse} alt="cotton farm" className="w-64 m-auto cursor-pointer ease-in-out duration-200 hover:scale-125" onClick={() => { setSelectHouse(1); selectHouseCards(0) }} aria-hidden="true" />
                    <img src={woolHouse} alt="wool farm" className="w-64 m-auto cursor-pointer ease-in-out duration-200 hover:scale-125" onClick={() => { setSelectHouse(2); selectHouseCards(1) }} aria-hidden="true" />
                    <img src={leatherHouse} alt="leather farm" className="w-64 m-auto cursor-pointer ease-in-out duration-200 hover:scale-125" onClick={() => { setSelectHouse(3); selectHouseCards(2) }} aria-hidden="true" />

                    <img src={pineappleHouse} alt="pineapple farm" className="w-64 m-auto cursor-pointer ease-in-out duration-200 hover:scale-125" onClick={() => { setSelectHouse(4); selectHouseCards(3) }} aria-hidden="true" />
                    <img src={lineHouse} alt="line farm" className="w-64 m-auto cursor-pointer ease-in-out duration-200 hover:scale-125" onClick={() => { setSelectHouse(5); selectHouseCards(4) }} aria-hidden="true" />
                    <img src={silkHouse} alt="silk farm" className="w-64 m-auto cursor-pointer ease-in-out duration-200 hover:scale-125" onClick={() => { setSelectHouse(6); selectHouseCards(5) }} aria-hidden="true" />

                </div> :
                !listItemsFilter
                    ? <div className="h-80 flex justify-center items-center"><Loader /></div>
                    :
                    <>
                        <MarketFilters openFilter={openFilter} setOpenFilter={setOpenFilter} listItems={listItemsFiltered} setListItemsFilter={setListItemsFiltered} />
                        <section className=" pt-4 pb-4">
                            <button className="bg-darkPink px-6 py-2 text-white text-md font-bold rounded-xl hover:scale-110 ease-in-out duration-200 mb-5 md:mb-0" onClick={() => setSelectHouse(0)}>Back</button>
                            <section className="flex flex-wrap flex-col justify-center md:flex-row md:justify-between items-center mb-5 ">
                                <div className='p-3 bg-[#F0C1DC]  rounded-md ' >
                                    <button onClick={() => { setOpenFilter(true) }} className='bg-[#AA2E74] px-2 py-1 mx-auto text-white font-bold text-md rounded-md'>
                                        Sort and Filters
                                    </button>
                                </div>
                                <div className="bg-backgroundOp rounded-lg px-6 ">
                                    <h3 className="pb-2 text-lg mt-5 md:mt-0 "> <strong>Filter by Waifu Rarity:</strong>  </h3>
                                    <div className="flex justify-center items-center pb-2 ">
                                        <button onClick={() => { handleFilterStart("all"); setSelectStar(0) }} className={`${selectStar === 0 ? "bg-primaryDark" : "bg-background"} w-10 rounded-md text-white h-10  active:scale-90`}>All</button>
                                        <img onClick={() => { handleFilterStart("1"); setSelectStar(1) }} src={start1} alt="start-1" className={`${selectStar === 1 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} aria-hidden="true" />
                                        <img onClick={() => { handleFilterStart("2"); setSelectStar(2) }} src={start2} alt="start-2" className={`${selectStar === 2 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} aria-hidden="true" />
                                        <img onClick={() => { handleFilterStart("3"); setSelectStar(3) }} src={start3} alt="start-3" className={`${selectStar === 3 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} aria-hidden="true" />
                                        <img onClick={() => { handleFilterStart("4"); setSelectStar(4) }} src={start4} alt="start-4" className={`${selectStar === 4 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} aria-hidden="true" />
                                        <img onClick={() => { handleFilterStart("5"); setSelectStar(5) }} src={start5} alt="start-5" className={`${selectStar === 5 ? "w-9" : "w-8"} ml-2 cursor-pointer  hover:scale-110 active:scale-90`} aria-hidden="true" />
                                    </div>
                                </div>
                            </section>

                            {listItemsFilter.length > 0 ? <PaginationComponent openFilter={openFilter} buyWaifu={buyWaifu} activeIcon={selectHouse - 1} item={"waifuMarket"} listItems={listItemsFiltered} paginationCant={12} balance={topData?.balancePeach} />
                                : <> {filtered ? <NotFilter /> : <HaventWaifus />} </>}  </section>
                    </>
            }


        </div >
    )
}