import * as React from "react";
import Layout from "../components/layouts/Layout";
import MarketWaifuComponent from "../components/MarketWaifu/MarketWaifu";
import harvestBackground from "../assets/img/backgroundSections/harvestBackground.png";


// markup
const MarketWaifu = () => {
  const [marketBackground, setMarketBackground] = React.useState(harvestBackground);
  const [marketBgEffect, setMarketBgEffect] = React.useState('');
  return (
    <Layout background={marketBackground} effects={marketBgEffect}>
      <MarketWaifuComponent setMarketBackground={setMarketBackground} setMarketBgEffect={setMarketBgEffect} />
    </Layout>
  )
}

export default MarketWaifu
